<!-- Cart Sidebar Start -->
<div class="cart-sidebar-wrapper">
    <aside class="cart-sidebar" [ngClass]="cartmethod ? '' : 'cart-open'">
        <div class="cart-sidebar-header">
            <h3>Your Cart</h3>
            <div class="close-btn cart-trigger close-dark" (click)="cartToggle()">
                <span></span>
                <span></span>
            </div>
        </div>
    </aside>
    <div class="cart-sidebar-overlay cart-trigger" (click)="cartToggle()">
    </div>
</div>
<!-- Cart Sidebar End -->
<!-- Search Form Start-->
<div class="search-form-wrapper" [ngClass]="searchmethod ? '' : 'open'">
    <div class="search-trigger close-btn" (click)="searchToggle()">
        <span></span>
        <span></span>
    </div>
</div>
<!-- Search Form End-->
<!-- Aside (Mobile Navigation) -->
<aside class="main-aside" [ngClass]="navmethod ? '' : 'open'">
    <a class="navbar-brand" routerLink="/"> <img src="assets/img/logo.png" alt="logo"> </a>
    <div class="aside-scroll">
        <app-mobilemenu></app-mobilemenu>
    </div>
</aside>
<div class="aside-overlay aside-trigger" (click)="toggleNav()"></div>
<!-- Header Start -->
<header class="main-header header-1">
    
    <div class="container">
        <nav class="navbar">
            <!-- Logo -->
            <a class="navbar-brand" routerLink="/"> <img src="assets/img/logo.png" alt="logo"> </a>
            <!-- Menu -->
            <app-menu></app-menu>
            <div class="header-controls">
                <ul class="header-controls-inner">

                </ul>
                <!-- Toggler -->
                <div class="aside-toggler aside-trigger" (click)="toggleNav()">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </nav>
    </div>
</header>
<!-- Header End -->